.wrap-content {
	
	position: relative;
	z-index: 1;
	padding: 30px 0 70px;
	@include respond-to('large') {
		padding: 30px 0 50px;
	}
	@include respond-to('small') {
		padding: 25px 0 35px;
	}
	@include respond-to('extra-small') {
		padding: 15px 0 20px;
	}
}

.title {
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	color: $accent;
	margin: 0;
	@include respond-to('extra-small') {
		font-size: 25px;
	}
}

.hr-content {
	border-top: 1px solid #3c3c3c;
	margin: 30px 0;
	@include respond-to('extra-small') {
		margin: 15px 0;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 1.2;
		color: $accent;
		margin: 20px 0 20px;
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 300;
		color: #fff;
		line-height: 1.4;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}