.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #000;
	// box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
	// transition: all 0.4s ease;
}

.header-desktop {
	min-height: 100px;
	transition: height 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
	}
}

.logo {
	position: absolute;
	#{$right}: 12px;
	top: 0;
	height: 100%;
	transition: all 0.2s ease;
	& a {
		display: block;
		// padding: 0 15px;
		// background-color: #000;
		transition: all 0.2s ease;
	}
	& img {
		height: 79px;
		margin-top: 10px;
		transition: all 0.2s ease;
		@include respond-to('1450') {
			height: 60px;
			margin-top: 20px;
		}
	}
}

.sub-nav {
	height: 50px;
	margin-#{$right}: 245px;
	border-bottom: 1px solid $accent;
	transition: all 0.2s ease;
	padding: 6px 0;
	@include respond-to('1450') {
		margin-#{$right}: 170px;
	}
	@include respond-to('huge') {
		padding-top: 10px;
	}
	& > ul {
		display: inline-block;
		margin: 0;
		list-style: none;
		transition: all 0.2s ease;
		& > li {
			// margin: 0 5px;
			display: inline-block;
			vertical-align: middle;
			color: #777777;
			&:first-child {
				margin-#{$right}: 0;
			}
			& > a {
				display: inline-block;
				padding: 6px 7px;
				vertical-align: middle;
				color: #fff;
				font-size: $font-size-base;
				font-weight: 600;
				@include link_no-hover(#fff);
				@include respond-to('huge') {
					font-size: 16px;
				}
			}
		}
	}
	&__menu {
		padding: 0;
		& a {
			&:hover {
				background-color: $accent;
			}
			&.active {
				background-color: $accent;
			}
		}
	}
	&__cont {
		float: $left;
		padding-#{$left}: 165px;
		@include respond-to('1450') {
			padding-#{$left}: 135px;
		}
		& i {
			margin-#{$left}: 10px;
			font-size: 18px;
			vertical-align: middle;
			color: $accent;
		}
	}
}

.main-navi {
	display: block;
	margin-#{$right}: 245px;
	padding: side-values(6px 0 6px 165px);
	transition: padding 0.2s ease;
	@include respond-to('1450') {
		margin-#{$right}: 170px;
		padding: side-values(6px 0 6px 135px);
	}
	& > ul {
		display: inline-block;
		vertical-align: top;
		list-style: none;
		padding: 0;
		margin: 0;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			// padding-top: 17px;
		}
		& > li {
			float: $right;
			margin-#{$left}: 0;
			position: relative;
			& > a {
				display: inline-block;
				padding: 6px 7px;
				vertical-align: middle;
				color: #fff;
				font-size: $font-size-base;
				font-weight: 600;
				@include link_no-hover(#fff);
				@include respond-to('huge') {
					font-size: 16px;
				}
				&:hover {
					background-color: $accent;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					background-color: $accent;
				}
			}
		}
	}
}

.label-block {
	position: absolute;
	left: 0;
	top: 0;
	width: 173px;
	height: 160px;
	padding: 8px 25px;
	background-image: url(../images/header-label.png);
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
	@include respond-to('1450') {
		width: 150px;		
	}
	&__text {
		margin-bottom: 18px;
		color: #000;
		text-align: center;
		font-size: $font-size-base;
		font-weight: 700;
		@include respond-to('large') {
			margin-bottom: 10px;
		}
	}
	&__items {
		text-align: center;
	}
	&__item {
		margin-#{$left}: -4px;
		width: 33.33%;
		display: inline-block;
		text-align: center;
		&:nth-child(2) {
			padding-#{$left}: 3px;
		}
	}
} 

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 7px;
		float: $left;
		& img {
			width: 150px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.mobile-menu-btn {
	// margin-top: 20px;
}

// .main-header.scrolling {
// 	.header-desktop {
// 		height: 111px;
// 	}
// 	.sub-nav {
// 		height: 53px;
// 		padding: 0;
// 		& ul {
// 			padding: 11px 0 10px;
// 		}
// 	}
// 	.find-last {
// 		padding-top: 10px;
// 		& .search input {
// 			padding: side-values(9px 0 8px 25px);
// 		}
// 		& .search button {
// 			top: 8px;
// 		}
// 	}
// 	.main-navi {
// 		& ul {
// 			padding-top: 10px;
// 		}
// 	}
// 	.logo {
// 		& a {}
// 		& img {
// 			height: 86px;
// 			max-width: 226px;
// 			margin-top: 12px;
// 		}
// 	}
// 	.header-mobile {}
// }




.main-navi {
	display: inline-block;
	height: 100%;
	& > ul {
		display: inline-block;
		list-style: none;
		//   padding:32px 190px 0 0;
		margin: 0;
		height: 100%;
		@include respond-to('huge') {
			// padding: 32px 20px 0 0;
		}
		& > li {
			float: right;
			position: relative;
			height: 100%;
			&:hover {
				& > ul {
					display: block;
					opacity: 1;
					transition: 0.4s;
				}
			}
			&:last-child {
				& > span {
					opacity: 0;
					display: none;
				}
			}
			& > a {
				font-size: 17px;
				//   color: #002c5c;

				@include respond-to('large') {
					font-size: 17px;
				}
			}
			& > ul {
				padding: 0;
				margin: 0;
				list-style: none;
				background-color: #ffffff;
				position: absolute;
				top: 35px;
				right: 0px;
				z-index: 9;
				font-size: 17px;
				opacity: 0;
				display: none;
				transition: 0.4s;
				min-width: 250px;
				text-align: right;
				//   border-left: 1px solid #3d5184;
				@include respond-to('large') {
					top: 31px;
				}
				& > li {
					&:hover {
						background-color: #f4f4f4;
					}
					border-bottom: 1px solid #f3f3f3;
					position: relative;
					& > a {
						border-bottom: 1px solid #e1e1e1;
						transition: 0.3s;
						color: #002c5c;
						padding: 7px 12px;
						display: block;
						opacity: 1;
						&:hover {
							text-decoration: none !important;
						}
					}
					& > ul {
						padding: 0;
						margin: 0;
						list-style: none;
						background-color: #ffffff;
						position: absolute;
						top: 0;
						transition: 0.4s;
						left: 285px;
						z-index: 9;
						opacity: 0;
						display: none;
						min-width: 400px;
						text-align: center;
						& > li {
							//border-bottom: 1px solid #3a4f82;
							position: relative;
							&:hover {
								background-color: #f4f4f4;
								text-decoration: none !important;
							}
							& > a {
								&:hover {
									text-decoration: none !important;
								}
								transition: 0.3s;

								color: #002c5c;
								padding: 7px 12px;
								display: block;
								opacity: 1;
								border-bottom: 1px solid #f3f3f3;
								text-align: left;
							}
							& > ul {
								padding: 0 20px;
								margin: 0;
								list-style: none;
								background-color: #ffffff;
								position: absolute;
								top: 0;
								transition: 0.4s;
								right: 314px;
								z-index: 9;
								opacity: 0;
								display: none;
								min-width: 315px;
								text-align: center;
								& > li {
									position: relative;
									float: right;
									width: 50%;
									&:nth-child(2n+1) {
										clear: both;
									}
									& > a {
										transition: 0.3s;
										color: #2d2a6e;
										padding: 12px 0px;
										display: block;
										opacity: 1;
										&:hover {
											color: #002c5c;
										}
									}
								}
							}
							&:hover {
								& > ul {
									transition: 0.3s;
									display: block;
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						& > ul {
							transition: 0.3s;
							display: block;
							opacity: 1;
						}
					}
				}

			}
		}
	}
}

//   .main-navi>ul>li>ul>li>a:after{
// 	content: "\203A";

//     position: absolute;
//     right: 5px;
 
//   }

.right-arrow {
	position: absolute;
	top: 34%;
	width: 15px;
	height: 15px;
	right: 9px;
	background-image: url(/~clicktours/assets/images/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-position: center;
}