.wrapper-all-content {
	background-color: #fff;
	
	 
	position: relative;
	width: 100%;
	background-position: center; 
	background-size: cover;
	background-repeat: no-repeat;
	&_hp {
		padding: 55px 0;
		@include respond-to('large') {
			padding: 35px 0;
		}
		@include respond-to('small') {
			padding: 25px 0;
		}
		@include respond-to('extra-small') {
			padding: 15px 0;
		}
		&::after {
			// z-index: auto;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.6);
		}
	}
	&_content {
		&::after {
			// z-index: auto;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.85);
		}
	}
}

.hp-main {
	position: relative;
	z-index: 1;
	margin: 0 -15px;
	@include respond-to('large') {
		margin: 0 -10px;
	}
	&__right {
		display: inline-block;
		width: 50%;
		margin-#{$left}: -4px;
		vertical-align: top;
		@include respond-to('medium') {
			width: 100%;
			margin-#{$left}: 0;
		}
	}
	&__left {
		display: inline-block;
		width: 50%;
		@include respond-to('medium') {
			width: 100%;
		}
	}
}

.hp-box-wrap {
	padding: 15px;
	@include respond-to('large') {
		padding: 10px;
	}
	&_short {
		float: $right;
		width: 50%;
		@include respond-to('extra-small') {
			width: 100%;
		}
		& .box-text {
			max-width: 265px;
			width: 96%;
		}
	}
}

.hp-box {
	position: relative;
	display: block;
	height: 240px;
	padding: 15px;
	@include respond-to('large') {
		padding: 10px;
	}
	@include respond-to('1100') {
		height: 200px;
	}
	&_bg-black {
		background-color: rgba($color: #000000, $alpha: 0.8);
	}
	&_bg-red {
		background-color: rgba($color: $accent, $alpha: 0.8);
	}
	&_bg-white {
		background-color: rgba($color: #ffffff, $alpha: 0.8);
	}
}

.ribbon {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 100px;
	height: 100px;
	text-align: $right;
}

.ribbon span {
	font-size: 20px;
	font-weight: bold;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	line-height: 32px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 135px;
	display: block;
	background: #79A70A;
	background: linear-gradient(#BD2426 0%, #BD2426 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 21px;
	left: -30px;
}

.ribbon span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #BD2426;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #BD2426;
}

.ribbon span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #BD2426;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #BD2426;
}

.main-box {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 16px;
	&_bord-red {
		border: 1px solid $accent;
	}
	&_bord-black {
		border: 1px solid #000;
	}
	&_bord-white {
		border: 1px solid #fff;
	}
	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 454px;
		width: 96%;
		text-align: center;
		z-index: 1;
	}
}

.box-text {
	&__title {
		position: relative;
		display: inline-block;
		margin-bottom: 25px;
		font-size: 40px;
		font-weight: 400;
		line-height: 0.9;
		@include respond-to('1100') {
			font-size: 30px;
		}
		&_white {
			color: #fff;
		}
		&_black {
			color: #000;
		}
		&_red {
			color: $accent;
			margin-bottom: 15px;
		}
		&_mb0 {
			margin-bottom: 0;
		}
	}
	&__desc {
		font-size: 18px;
		font-weight: 400;
		line-height: 1.1;
		&_white {
			color: #fff;
		}
		&_black {
			color: #000;
		}
	}
	&__btn {
		display: block;
		max-width: 162px;
		width: 100%;
		margin: 0 auto;
		padding: 8px 20px;
		background-color: #000;
		color: #fff;
		font-size: 20px;
		font-weight: 400;
		@include link_no-hover(#fff);
		&_red {
			max-width: 200px;
			width: 100%;
			margin-top: 17px;
			padding: 11px 20px;
			background-color: $accent;
		}
	}
	&__link {
		margin-top: 10px;
		font-size: 18px;
		font-weight: 700;
		color: $accent;
	}
	&__subtitle {
		display: block;
		margin-bottom: 5px;
		font-size: 25px;
		font-weight: 300;
		color: #fff;
		line-height: 0.9;
	}
	&__gallery-btn {
		display: inline-block;
		max-width: 294px;
		width: 100%;
		padding: 8px 20px;
		background-color: #000;
		border: 1px solid #fff;
		color: #fff;
		font-size: 40px;
		font-weight: 400;
		@include link_no-hover(#fff);
	}
	&__signature {
		text-align: center;
		margin-top: 15px;
	}
}

.decor-elem {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	&_left {
		left: -50px;
	}
	&_right {
		right: -50px;
	}
}

.hp-box-slider {
	height: 510px;
	width: 100%;
	@include respond-to('1100') {
		height: 420px;
	}
	@include respond-to('extra-small') {
		height: 380px;
	}
	@include respond-to('mobile-l') {
		height: 350px;
	}
	@include respond-to('mobile-m') {
		height: 300px;
	}
	&__item {
		position: relative;
		min-height: 510px;
		display: none;
		background-position: center;
		background-size: cover;
		@include respond-to('1100') {
			min-height: 420px;
		}
		@include respond-to('extra-small') {
			min-height: 380px;
		}
		@include respond-to('mobile-l') {
			min-height: 350px;
		}
		@include respond-to('mobile-m') {
			min-height: 300px;
		}
		&:first-child {
			display: block;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.5);
		}
		& .main-box__text {
			max-width: 95%;
			width: 100%;
		}
		& .box-text__title {
			margin-bottom: 15px;
		}
		& .box-text__desc {
			max-width: 125px;
			width: 100%;
			margin: 0 auto;
		}
	}
	& .slick-dots {
		& li {
			margin: 0 2px;
			padding: 0;
			height: 22px;
			& button {
				padding: 0;
				&::before {
					content: '';
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 12px;
					height: 12px;
					border: 1px solid #fff;
					border-radius: 50%;
					opacity: 1;
				}
			}
		}
		& li.slick-active {
			& button {
				&::before {
					border: 1px solid #fff;
					background-color: #fff;
					opacity: 1;
				}
			}
		}
	}
}