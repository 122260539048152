footer {
	width: 100%;
	position: relative;
	background-color: #000;
	z-index: 1;
	padding: 11px 0 11px;
	font-size: 0;
	vertical-align: middle;
}

.ftr-logo {
	// float: $right;
	display: inline-block;
	width: 20%;
	vertical-align: middle;
	@include respond-to('medium') {
		width: 35%;
		text-align: center;
	}
	@include respond-to('extra-small') {
		width: 100%;
	}
	& img {
		height: 58px;
	}
}

.credit-info-txt {
	display: inline-block;
	width: 60%;
	vertical-align: middle;
	text-align: center;
	@include respond-to('medium') {
		// display: block;
		text-align: $right;
		// margin-bottom: 10px;
		width: 65%;
	}
	@include respond-to('extra-small') {
		// margin-right: 0;
		width: 100%;
		margin-top: 10px;
	}
	& > p {
		font-size: 20px;
		color: #fff;
		font-weight: 400;
		margin: 0;
		@include respond-to('medium') {
			font-size: 16px;
			text-align: center;
		}
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.site-footer__credit-holder {
	display: inline-block;
	width: 20%;
	vertical-align: middle;
	@include respond-to('medium') {
		width: 100%;
		text-align: center;
	}
}

// .wrap-social {
// 	position: relative;
// 	display: inline-block;
// 	margin-#{$right}: 25px;
// 	@include respond-to('large') {
// 		margin-#{$right}: 20px;
// 	}
// 	@include respond-to('medium') {
// 		display: block;
// 		text-align: center;
// 		margin: 0;
// 		margin-bottom: 10px;
// 	}
// 	& > a {
// 		& > i {
// 			color: #777777;
// 			margin-#{$left}: 15px;
// 		}
// 		&:hover {
// 			& > .fa-twitter {
// 				color: $clr-twitter;
// 			}
// 			& > .fa-youtube {
// 				color: $clr-youtube;
// 			}
// 			& > .fa-facebook-f {
// 				color: $clr-facebook;
// 			}
// 		}
// 	}
// }
