.start-page {
	position: relative;
	min-height: 100vh;
	// min-height: 700px;
	width: 100%;
	// background-color: #000;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background: #fff !important;
	@include respond-to('medium') {
		// min-height: 400px;
	}
	&::after {
		content: '';
		// position: absolute;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		// background-color: rgba($color: #000000, $alpha: 0.7);
	}
	&__logo {
		// height: 23vh;
		position: relative;
		z-index: 1;
		padding-top: 20px;
		// padding-top: 3.9vw;
		text-align: center;
		@include respond-to('small') {
			padding-top: 10px;
		}
		& a {
			& img {
				// max-width: 382px;
				width: 459px;
				// width: 22vw;
				@include respond-to('1100') {
					max-width: 180px;
					// width: 30vw;
				}
			}
		}
	}
	&__list {
		// height: 77vh;
    	margin-top: 30px;
		// padding-top: 8.3vw;
		position: relative;
		z-index: 1;
		width: 100%;
		float: right;
		@include respond-to('larger') {
			margin-top: 120px;
		}
		@include respond-to('1100') {
			margin-top: 75px;
		}
		& ul {
			// position: absolute;
			// top: 45%;
			// #{$left}: 50%;
			// transform: translate(-50%, -50%);
			width: 90%;
			margin: 0 auto;
			padding: 0;
			list-style: none;
			text-align: center;
			& li {
				position: relative;
				padding: 35px 0;
				// padding: 3.7vh 0;
				// line-height: 86px;
				border-style: solid;
				border-top-width: 1px;
				border-bottom-width: 0;
				border-image: linear-gradient(to right, rgba(255, 0, 0, 0) 10%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 90%) 1 0%;
				@include respond-to('1100') {
					padding: 25px 0;
				}
				@include respond-to('medium') {
				border-image: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 90%) 1 0%;
				}
				@include respond-to('small') {
					// line-height: 60px;
					padding: 15px 0;
				}
				&:last-child {
					border-bottom-width: 1px;
				}
				& a {
					position: relative;
					color: #fff;
					font-size: 50px;
					// font-size: 5.3vh;
					font-weight: 600;
					line-height: 0.8;
					vertical-align: middle;
					@include link_no-hover(#fff);
					// padding-#{$right}: 15px;
					// margin-right: 83px;
					@include respond-to('medium') {
						font-size: 40px;
					}
					@include respond-to('small') {
						font-size: 35px;
					}
					@include respond-to('extra-small') {
						font-size: 30px;
					}
					& span {
						vertical-align: middle;
						color: #000;
					}
					& img {
						max-width: 80px;
						// width: 4.2vw;
						margin-#{$left}: 20px;
						// vertical-align: bottom;
						@include respond-to('medium') {
							max-width: 60px;
						}
						@include respond-to('extra-small') {
							max-width: 40px;
						}
					}
				}
			}
		}
	}
}
.start-page-text-content{
	width: 100%;
	float: right;
	.start-page-text-content-inner{
		width: 910px;
		max-width: 100%;
		margin: 0 auto;
		text-align: justify;
		padding: 45px 20px;
		font-size: 20px;
		line-height: 120%;
		@include respond-to('small') {
			padding: 15px 20px;
			font-size: 19px;
		}
	}
}
.start-logos-wrap{
	width: 100%;
	float: right;
	.start-logos-wrap-inner{
		width: 910px;
		max-width: 100%;
		margin: 0 auto;
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			li{
				width: 33%;
				float: right;
				text-align: center;
				padding: 10px 20px;
					@include respond-to('small') {
						padding: 9px 7px;
					}
				a{

				}
				img{

				}
			}
		}
	}
}
.start-slider{
	width: 100%;
	float: right;
}
.start-slider-inner{
	width: 100%;
	float: right;
    padding: 30px 0;
}